:root {
  --gd-main-color: #6C2B95;
  --gd-secondary-color: #E434EC;
  --gd-third-color: #953ACF;
  --gd-black-color: #000000;
  --gd-grey-color: #424242;
  --gd-white-color: #ffffff;
  --gd-bg-grey: #f8f8f8;
  --gd-bg-main: #ffffff;
  --bs-tertiary-bg: #ffffff;
}
