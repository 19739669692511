@media only screen and (min-width: 320px) and (max-width: 812px) {
  nz-sider {
    display: none !important;
  }

  .download {
    padding: 0 10px !important;

    a {
      width: 100%;
    }
  }

  .logo-mobile {
    display: block !important;
  }

  .offcanvas {
    background-color: var(--gd-main-color);
  }

  .offcanvas-header {
    background-color: #ffffff;
  }

  .offcanvas-body {
    padding: 0;
  }

  .trigger {
    display: none;

    &.mobile {
      display: block !important;
    }
  }

  .payments {
    .status {
      margin-bottom: 1rem !important;

      nz-card {
        margin-right: 0 !important;
      }
    }
  }

  .executor {

    .header-img {
      display: none !important;
    }

    .header-container {
      display: flex;

      .header-text {
        display: flex;
      }

      .img-executor-mobile {
        width: 134px;
        height: 199px;
        margin-top: 41px;

        img {
          width: 134px !important;
          height: 199px !important;
          margin-left: -20px !important;
        }

      }

    }

    .recommendation-text {
      h3 {
        margin-left: -155px !important;
      }
    }

    .container-main {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;

      .container-card {
        flex-wrap: wrap;
        width: 100%;

        .card-container-one,
        .card-container-two,
        .card-container-three {
          width: 100% !important;
          margin-right: 0 !important;
          margin: 16px 2px;
          padding: 0 !important;

          .card-img-one {
            padding: 0 !important;
          }

          .card-img-two {
            flex-direction: column-reverse !important;
            padding: 0 !important;

            img {
              width: 124px !important;
              height: 113px !important;
            }
          }
        }

      }

      .card-container-two {
        margin-bottom: 18px;

        .header-text-two {
          padding: 0 !important;

        }
      }

      .header-text-one {
        margin: 0 !important;
      }

      .card-img-three {
        flex-direction: column-reverse !important;

        img {
          width: 88px !important;
          height: 116px !important;
        }
      }

    }

    .btn-executor-1 {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .btn-executor-2 {
      display: flex;
      flex-direction: column-reverse;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }

  }

  .info {

    .info-container {
      height: 196px !important;

      .info-img {
        display: flex;
        flex-direction: column-reverse;

        img {
          width: 110px !important;
          height: 163px !important;
          margin-right: -162px !important;
          margin-left: 0 !important;
        }
      }

      .info-text {
        margin-left: 110px !important;
        margin-top: 20px !important;
      }
    }

    .btn-info-1 {
      margin-bottom: 20px;
      width: 100% !important;

    }

    .btn-info-2 {
      display: flex;
      flex-direction: column-reverse;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }

  }

  .recipient {

    .beneficiaries-img-header {
      display: none;
    }

    .container-beneficiaries-header {
      display: flex;
      height: 250px !important;

      .beneficiaries-text-header {
        display: flex;
      }

      .img-beneficiaries-mobile {
        img {
          margin-top: 51px;
        }
      }
    }

    .recommendation-beneficiaries {
      h3 {
        margin-left: -130px !important;
      }
    }

    .container-beneficiaries-main {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;

      .container-beneficiaries-card {
        flex-wrap: wrap;
        width: 100%;
      }

      .card-beneficiaries-one,
      .card-beneficiaries-two,
      .card-beneficiaries-three {

        width: 100% !important;
        margin-right: 0 !important;
        margin: 16px 2px;
      }

      .beneficiaries-text-one {
        display: flex;
        min-width: 0 !important;
        padding: -15px !important;
      }

      .beneficiaries-text-three {
        margin: -15px !important;
      }

      p {
        font-size: 14px !important;
      }
    }

    .btn-recipient-1 {
      flex-direction: column;
      margin-bottom: 20px;


      .btn-primary {
        margin: 0 !important;
        margin-top: 20px !important;
      }
    }

    .btn-recipient-2 {
      display: flex;
      flex-direction: column-reverse;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }

  }

  .obituary {

    .card-container-obituary,
    .list-cards {
      display: none !important;
    }

    .instruction-text {
      height: 100px !important;

      [nz-carousel-content] {
        height: 100px !important;
        padding: 12px !important;
        border-radius: 5px;
        border: 1px solid var(--gd-secondary-color);
        background: var(--gd-secondary-color);
        overflow: hidden;

        h3 {
          color: var(--gd-white-color);
          font-family: 'poppins-semibold';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          user-select: none;
        }

        p {
          color: var(--gd-white-color);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          user-select: none;
        }

      }
    }

    .instruction-item {
      height: 140px !important;

      [nz-carousel-content] {
        height: 140px !important;
        padding: 12px !important;
        border: 1px solid var(--gd-secondary-color);
        background: var(--gd-white-color);
        color: var(--gd-secondary-color);
        overflow: hidden;

        i {
          color: var(--gd-main-color);
          font-weight: 700;
          margin-right: 7px;
          float: left;
          border: 1px solid var(--gd-main-color);
          border-radius: 100%;
          padding: 5px 9px;
          margin-top: -3px;
        }

        h3 {
          color: var(--gd-main-color);
          font-family: 'poppins-semibold';
          font-size: 16px;
          font-weight: 700;
          margin: 12px;
          margin-bottom: 0;
          user-select: none;
        }

        p {
          color: var(--gd-grey-color);
          margin-top: 16px;
          font-size: 12px;
          font-weight: 400;
          user-select: none;
        }
      }
    }

    .text {
      margin-top: 40px;
    }

    .form-control {
      height: 437px !important;
      margin-top: -26px !important;
    }

    .btn-ia {
      width: 100% !important;
      margin-top: 70px !important;
    }

    .btn-obituary-2 {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 0;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }

  }

  .funeral {

    .header-container-text {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      width: 100% !important;
      height: 310px !important;
      padding: 30px 16px !important
    }

    .header-img {

      img {
        width: 123px !important;
        height: 123px !important;
        margin-left: 0 !important;
      }
    }

    .card-text {
      margin-top: 10px;
      text-align: center;

      h3 {
        margin-left: 0 !important;
      }

      p {
        margin-left: 0 !important;
      }
    }

    .btn-funeral-1 {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .btn-funeral-2 {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 0;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }
  }

  .pets {

    .header-container-pets {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      width: 100% !important;
      height: 316px !important;
      padding: 30px 16px !important;
    }

    .card-img-pets {

      img {
        width: 124px !important;
        height: 124px !important;
        margin-left: 0 !important;
      }
    }

    .card-text-pets {
      margin-left: 0 !important;

      h3 {
        margin-left: 0 !important;
      }

      p {
        margin-left: 0 !important;
      }
    }

    .btn-pet-1 {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .btn-pet-2 {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 0;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }
  }

  .register {

    .header-container-register {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      width: 100% !important;
      height: 310px !important;
      padding: 30px 16px !important;

    }

    .card-img-register {

      img {
        width: 170px !important;
        height: 132px !important;
        margin-left: 0 !important;
      }
    }

    .card-text-register {
      margin-left: 0 !important;
      margin-top: 10px !important;

      h3 {
        margin-left: 0 !important;
      }

      p {
        margin-left: 0 !important;
      }
    }

    .btn-register-1 {
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 55px;
    }

    .btn-register-2 {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 0;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }
  }

  .account {

    .account-container {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      width: 100% !important;
      height: 306px !important;
      padding: 30px 16px !important;
      margin-top: -24px;
    }

    .img-account {
      img {
        width: 169px !important;
        height: 156px !important;
        margin-left: 0 !important;
      }
    }

    .text-account {

      h3 {
        margin-left: 0 !important;
      }

      p {
        margin-left: 0 !important;
      }
    }

    .btn-account-1 {
      flex-direction: column;
      margin-bottom: 20px;
      width: 100% !important;
      margin-top: 34px !important;
    }

    .btn-account-2 {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 0;

      .btn-outline-primary {
        display: flex;
        justify-content: space-around;
        margin-top: 20px !important;
        width: 100% !important;
      }

    }
  }

  .wishes {

    .wishes-container {
      display: flex;
      width: 100% !important;
      height: 305px !important;
      padding: 30px 16px !important;
      flex-direction: column !important;
    }

    .text-beneficiaries-ia {

      p {
        margin-top: 33px !important;
      }
    }

    .text-wishes {

      h3 {
        margin-left: 0 !important;
      }

      p {
        margin-left: 0 !important;
      }
    }

    button {
      width: 100% !important;
    }

    .btn-container {
      display: flex;
      flex-direction: column-reverse;

      button {
        &:last-child {
          margin-bottom: 0.5rem;
        }
      }
    }

    .message-mobile {
      width: 100% !important;
    }


  }

  .my-files {

    .my-files-container {
      display: flex;
      width: 100% !important;
      height: 296px !important;
      padding: 30px 16px !important;
      flex-direction: column !important;
    }

    .text-files {
      margin-top: 16px !important;

      h3 {
        margin-left: 0 !important;
      }

      p {
        margin-left: 0 !important;
      }
    }

    .uploader {
      width: 100% !important;
      height: 304px !important;
      margin-top: 10px !important;

      .text {

        h3 {
          color: var(--gd-grey-color);
          text-align: center;
          font-family: "Public Sans";
          font-size: 22px !important;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .info-container {

    a,
    i {
      display: none;
    }

    button {
      margin-left: 22px;
      margin-right: 0 !important;
    }
  }

  .health {

    .header {
      margin-bottom: 0 !important;
    }

    .card-title {
      display: flex !important;
      width: 100% !important;
      height: 290px !important;
      padding: 30px 24px !important;
      flex-direction: column !important;

      i {
        margin: 0 !important;
        margin-top: -30px !important;
      }
    }

    .step-card {
      display: flex !important;
      flex-direction: column !important;
    }

    .add-btn {
      .btn-primary {
        width: 100% !important;
      }
    }

    .button-container {
      display: flex;
      flex-direction: column-reverse;

      .btn-outline-primary {
        width: 100% !important;
        margin-top: 20px !important;

      }

      .btn-primary {
        width: 100% !important;
        margin-top: 20px !important;
      }
    }

  }

  .assets {

    .card-title {
      display: flex !important;
      width: 100% !important;
      height: 290px !important;
      padding: 30px 24px !important;
      flex-direction: column !important;

      i {
        margin: 0 !important;
        margin-top: -30px !important;
      }
    }

    .btn-assets-1 {
      .btn-primary {
        width: 100% !important;
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column-reverse;

      .btn-outline-primary {
        width: 100% !important;
        margin-top: 20px !important;
      }

      .btn-primary {
        width: 100% !important;
        margin-top: 20px !important;
      }
    }
  }

  .vault-container {
    .review-count {
      display: none !important;
    }

    .vault-progress {
      width: 100% !important;

      .access-vault {
        position: static !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 22px;

        button {
          width: 100%;
        }
      }
    }
  }

  .vault-desc {
    p {
      font-size: 12px !important;
    }
  }

  .vault-progress-bar {
    padding: 0 12px !important;
  }

  .vault {
    display: flex !important;
    flex-direction: column !important;
    padding: 10px !important;
    margin-bottom: 15px !important;

    .header {
      width: 100% !important;
      padding: 16px !important;
      flex-direction: column !important;
      align-items: center !important;
      height: 312px !important;

      .vault-icon {
        padding: 10px !important;
      }

      .text-header {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
      }
    }

    .container-text-button {
      flex-direction: column !important;
      width: 100% !important;
      height: auto !important;
      padding: 15px !important;
      align-items: flex-start !important;

      .text-button {
        h3 {
          font-size: 20px !important;
        }

      }
    }

    .card {
      min-height: 100% !important;
      min-width: 100% !important;
      height: 100% !important;
      padding: 26px 16px !important;
      justify-content: center !important;
      align-items: flex-start !important;

      .list-unstyled {
        margin-top: 0 !important;
      }

      .title-text-list {
        flex-direction: column !important;
        padding: 0 !important;
      }

      ul {
        margin-bottom: 0 !important;
        padding: 0 !important;

        li {
          min-width: 100% !important;
        }
      }

      .img-mandatory {
        display: none;
      }

      h3 {
        font-size: 20px !important;
      }

      .version-history-modal-content .header {
        span {
          font-size: 14px !important;
        }
      }

      .version-item .text-item {
        font-size: 14px !important;
      }
    }

    .vault-version {
      min-width: 100% !important;
      height: auto !important;
      padding: 40px 16px !important;
      flex-direction: column !important;
      align-items: center !important;

      .text-version {
        flex-direction: column !important;
        align-items: flex-start !important;

        h3 {
          font-size: 18px !important;
        }
      }

      .version-counter {
        width: 90% !important;
        height: 70px !important;
        margin-left: 14px !important;
        margin-top: 20px;

        i {
          font-size: 16px !important;
          margin: 0;
        }

        h3 {
          font-size: 20px !important;
        }

        .btn-primary {
          margin: 0 !important;
        }
      }
    }

    .vault-signature {
      flex-direction: column !important;
      width: 100% !important;
      height: auto !important;
      padding: 40px 16px !important;
      align-items: flex-start !important;

      .text-signature {
        h3 {
          font-size: 18px !important;
        }
      }

      .btn-primary {
        margin-left: 25px !important;
        margin-top: 34px !important;
      }
    }

    .title-video {
      margin-top: 20px !important;
    }

    .container-video {
      flex-wrap: wrap !important;
      display: flex;
      justify-content: space-around !important;
      gap: 18px !important;
      padding: 10px !important;

      .executor-video,
      .beneficiaries-video {
        flex-direction: column !important;
        padding: 24px 16px !important;
        width: auto !important;
        height: auto !important;
        padding: 24px 16px !important;

        .text-executor-video,
        .text-beneficiaries-video {
          text-align: center !important;

          h3 {
            font-size: 18px !important;
          }

          p {
            font-size: 12px !important;
          }
        }
      }
    }

    .sub-title {
      padding: 0 !important;
    }

    .card-list {
      padding: 0 !important;
    }

    .download {
      .btn-primary {
        width: 100% !important;
      }
    }

    .btn-back {
      display: flex;
      flex-direction: column;

      .btn-outline-primary {
        margin-right: 0 !important;
      }
    }

    .user-list {
      .user-card {
        width: 299px !important;
        height: 76px !important;

        i {
          font-size: 20px !important;
        }
      }
    }
  }

}

@media (min-width: 813px) {

  [nz-carousel],
  [nz-carousel-content],
  .instruction-text,
  .instruction-item {
    display: none !important;
  }

  .img-beneficiaries-mobile {
    display: none !important;
  }

  .img-executor-mobile {
    display: none !important;
  }
}
