/* You can add global styles to this file, and also import other style files */
@import './assets/styles/_mobile.scss';
@import './assets/styles/colors';
@import './../node_modules/remixicon/fonts/remixicon.css';

@font-face {
  font-family: poppins-light;
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: poppins-regular;
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: poppins-medium;
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: poppins-semibold;
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

* {
  font-family: 'poppins-light', sans-serif;
}

b,
h3 {
  font-family: 'poppins-medium';
}

a {
  color: var(--gd-main-color);

  &:hover {
    cursor: pointer;
    color: var(--gd-secondary-color);
  }
}

.avoid-scroll {
  overflow: hidden !important;
}

body {
  background-color: #424242;
}

.trigger {
  &.mobile {
    display: none;
  }
}

.logo-mobile {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  display: none;
}

.ant-steps-item-custom {
  ant-steps-item ant-steps-item-active ant-steps-item-custom .ant-steps-item-icon {
    >.ant-steps-icon {
      color: var(--gd-main-color) red;
    }
  }

  &.ant-steps-item-process {
    .ant-steps-item-icon {
      >.ant-steps-icon {
        color: var(--gd-main-color) !important;
      }
    }
  }

  &.ant-steps-item-done {
    .ant-steps-item-icon {
      >.ant-steps-icon {
        color: var(--gd-main-color);
      }
    }
  }
}

.btn-cotainer {
  padding: 2rem 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 1rem;
}

.btn {
  &.btn-primary {
    background-color: var(--gd-main-color);
    border-color: var(--gd-main-color);
    color: var(--gd-white-color);
    ;

    &:hover {
      background-color: var(--gd-white-color);
      color: var(--gd-main-color);
    }
  }

  &.btn-light {
    color: var(--gd-main-color) !important;
  }

  &.btn-outline-primary {
    border-color: var(--gd-main-color);
    color: var(--gd-main-color);

    &:hover {
      background-color: var(--gd-main-color);
      color: var(--gd-white-color);
      border-color: var(--gd-white-color);
    }
  }

  &.btn-pink {
    text-transform: uppercase;
    color: #fff;
    margin-right: 16px;
    font-family: 'poppins-medium';
    background: var(--gd-secondary-color);
    border-color: var(--gd-secondary-color);

    &:hover {
      background-color: transparent;
      color: var(--gd-secondary-color);
    }
  }
}

input,
textarea {
  &.hasError {
    border: 1px solid red !important;
  }
}

.ant-menu-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 15px;
}

.dashboard {
  .card-anchor {
    nz-card {
      .ant-card-head {
        border-bottom: 1px solid var(--gd-main-color);
        background-color: #fff;

        i {
          color: #fff;
        }
      }

      .ant-card-body {
        padding: 18px 18px 0 18px;
      }
    }

    .ant-card-extra {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0;
      width: 100%;
      align-items: center;
    }
  }
}

.ant-layout-sider {
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;

  &.ant-layout-sider-collapsed {
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }
}

.ant-picker {
  border-radius: 5px;
}

nz-card {
  &.checkout-card {
    border-radius: 10px;
    min-width: 297px;

    .ant-card-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .ant-card-head {
      background-color: var(--gd-main-color);
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      color: #fff;
      border-radius: 10px 10px 0 0;
      text-align: center;
    }
  }
}

label {
  &.ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border: 1px solid var(--gd-main-color);
      }
    }

    &.ant-checkbox-wrapper-checked {
      .ant-checkbox {
        &:hover {
          .ant-checkbox-inner {
            border: 1px solid var(--gd-main-color);
          }
        }

        &.ant-checkbox {
          .ant-checkbox-input:focus+.ant-checkbox-inner {
            border: 1px solid var(--gd-main-color);
          }


          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: var(--gd-main-color);
              border-color: var(--gd-main-color);
            }

            &::after {
              border: 1px solid var(--gd-main-color);
            }
          }
        }
      }
    }
  }
}

.detail {
  hr {
    display: none;
  }

  ul {
    margin-top: 11px;
    list-style: none;
    padding: 0;

    li {
      font-size: 16px;
      font-weight: 700;

      i {
        margin-right: 10px;
        font-size: 18px;
        color: #2A8400;
      }
    }
  }

  &.landing {
    hr {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 15px;
      }
    }
  }
}

.row {
  &.row-spacing-6 {
    .col-md-6 {
      width: 49%;

      &:first-child {
        margin-right: 1%;
      }

      &:last-child {
        margin-left: 1%;
      }
    }
  }
}

table {
  tr {
    td {
      a {
        i {
          font-size: 18px;
        }

        &:first-child {
          i {
            color: #2A8400;
          }
        }

        &:last-child {
          i {
            color: #EF6565;
          }
        }
      }
    }
  }
}

.simplebar-content {
  height: 100%;
}

.termSubTitle {
  text-align: left !important;
  margin: 2rem 0;
}

.termText {
  text-align: left !important;
}

.upgrade {
  .ant-card-body {
    display: flex;
    flex-direction: row;
    padding: 0;
  }
}

.nextBill,
.lastBill {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.ant-alert-warning {
  border: #FFEF99;
  background-color: #FFEF99;
}

.alert-w {
  margin-bottom: 11px;
}

.alert-w-icon {
  font-size: 24px;
  color: var(--gd-main-color);
}

.form-check-input {
  &:checked {
    background-color: var(--gd-main-color);
    border-color: var(--gd-main-color);
  }

  &:focus {
    border-color: rgba($color: var(--gd-main-color), $alpha: 0.7);
    box-shadow: 0 0 0 0.25rem rgba($color: var(--gd-main-color), $alpha: 0.25);
  }
}


.lang-btn {
  img {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.flag {
  margin-right: 5px;
}

.simple-modal {
  &.container {
    max-width: 100%;

    &>div {
      padding: 10px;
    }
  }

  &.title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    padding-bottom: 20px;
    text-align: left;
  }

  &.action {
    padding: 10px 20px 0 20px;
    border-top: 2px solid #CECECE;
    width: 100%;
  }

  &.body {
    display: block;
    color: #424242;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    border-top: 2px solid #CECECE;
    width: 100%;
    margin: 0;
    padding: 50px;

    img {
      width: 248px;
      margin-bottom: 20px;
    }
  }

  &.btn-cancel {
    background-color: transparent;
    border: 1px solid var(--gd-main-color);
    color: var(--gd-main-color);
  }

  &.btn-confirm {
    background-color: var(--gd-main-color);
  }
}

.cancel-modal {
  &.container {
    max-width: 100%;

    &>div {
      padding: 10px;
    }
  }

  &.title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    padding-bottom: 20px;
    text-align: left;
  }

  &.action {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px 0 20px;
    border-top: 2px solid #CECECE;
  }

  &.body {
    display: block;
    color: #424242;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    border-top: 2px solid #CECECE;
    width: 100%;
    margin: 0;
    padding: 50px;

    img {
      width: 248px;
      margin-bottom: 20px;
    }
  }

  &.btn-cancel {
    background-color: transparent;
    border: 1px solid var(--gd-main-color);
    color: var(--gd-main-color);
  }

  &.btn-confirm {
    background-color: var(--gd-main-color);
  }
}

.ant-progress-bg {
  background-color: var(--gd-secondary-color);
}

.ant-progress-text {
  color: #ffffff;
}

.ant-menu-sub {
  background-color: var(--gd-third-color) !important;
}

.ant-menu-dark {
  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: var(--gd-white-color) !important;

      span {
        color: var(--gd-main-color);
      }
    }
  }
}

.userNotFoundText {
  padding: 10px 40px;

  h3 {
    color: #424242;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  span {
    color: #424242;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.ant-tabs-tab-btn {
  color: var(--gd-main-color) !important;
}

.ant-tabs-ink-bar {
  background-color: var(--gd-main-color) !important;
}

.ant-radio-button-wrapper-checked {
  color: #fff !important;
  background: var(--gd-main-color) !important;
  border-color: var(--gd-main-color) !important;

  &:not(.ant-radio-button-wrapper-disabled) {
    &::before {
      background-color: var(--gd-main-color) !important;
    }
  }
}

.ant-upload-drag-icon {
  span {
    svg {
      fill: var(--gd-main-color);
    }
  }
}

.ant-upload {
  &.ant-upload-drag {
    &:not(.ant-upload-disabled) {
      &:hover {
        border-color: var(--gd-main-color);
      }
    }
  }
}

nz-select {
  padding: 20px 0 0 0px !important;

  nz-select-top-control {
    border: none !important;
    box-shadow: none !important;
  }
}

td {
  &.action {
    a {
      &:first-child {
        margin-right: 0.275rem;
        padding-right: 0.275rem;
        border-right: 1px solid #cecece;
      }
    }
  }
}

.healthCollapse {
  .ant-collapse-header {
    color: var(--gd-main-color) !important;
    font-family: poppins-medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;

    div {
      display: none;
    }
  }
}

.message-contact,
.text-contact {

  h3 {
    color: var(--gd-grey-color);
    font-family: 'poppins-semibold';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    padding-top: 38px;
    text-align: center;

    span {
      color: var(--gd-main-color);
      font-family: 'poppins-semibold';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

  }
}

.type-title {
  .ant-collapse-header {
    color: var(--gd-main-color) !important;
    font-family: poppins-medium;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
  }
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
}

.ant-collapse {
  width: 100% !important;
}
